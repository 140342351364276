@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

a,
p,
span,
div {
  font-family: 'Lato', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-card-body {
  height: 100%;
  padding: unset;
}

.ant-tree-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tree-list-holder span {
  font-family: var(--text-family-content);
  font-weight: var(--text-weight-normal);
  line-height: var(--text-line-heights-sm);
  font-size: calc(var(--text-content-sm) * 1px);
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: var(--primary-600);
  border-color: transparent;
  border-radius: 3px;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--primary-600);
}

.ant-tree-checkbox-inner {
  width: 18px;
  height: 18px;
}

.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
  display: flex;
  align-items: center;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: flex;
  align-items: center;
}

.custom-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #00356d !important;
}

.custom-switch .ant-switch-handle::before {
  background-color: #666666;
  bottom: 1px;
}

.custom-switch.ant-switch-handle::before {
  margin-bottom: 1px;
}

.custom-switch.ant-switch-checked {
  background-color: #d1e9ff !important;
}

.custom-switch.ant-switch {
  background-color: #f5f5f5;
}

.custom-switch .ant-switch-inner {
  color: var(--neutral-900);
  font-family: 'lato', sans-serif;
}

.custom-switch.ant-switch-checked {
  border: 1px solid #6bb8ff !important;
}

.custom-switch.ant-switch {
  border: 1px solid #cecece;
}

.custom-button {
  border-radius: 4px;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  transition: 400ms;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: fit-content;
  cursor: pointer;
}

.custom-button.ant-btn:not(.icon) {
  padding: 0px 15px;
}

.custom-button.ant-btn.icon {
  padding: 2px;
  height: 28px;
  width: 28px;
}

.custom-button.ant-btn-background-ghost:not(:disabled) {
  background-color: transparent !important;
  color: var(--primary-600) !important;
  border: 1px solid var(--primary-600) !important;
}

.custom-button.ant-btn-background-ghost:not(:disabled):hover {
  background-color: var(--primary-10) !important;
}

.custom-button.ant-btn-primary:not(:disabled) {
  background-color: var(--primary-600);
  border-color: transparent;
  color: var(--primary-10);
}

.custom-button.ant-btn-primary:not(:disabled) {
  background-color: var(--primary-700);
}

.custom-button.ant-btn-link:not(:disabled):not(.ant-btn-dangerous) {
  color: var(--primary-600);
}

.custom-button.ant-btn-link.ant-btn-dangerous:not(:disabled) {
  color: var(--feedbacks-negative-500);
}

.custom-button.ant-btn-link.ant-btn-dangerous:not(:disabled):hover {
  background-color: var(--feedbacks-negative-100);
}

.custom-button.ant-btn-link:not(:disabled):hover {
  background-color: var(--primary-10);
}

.custom-button.ant-btn-primary.ant-btn-dangerous:not(:disabled):not(.ant-btn-background-ghost) {
  background-color: var(--feedbacks-negative-500) !important;
  color: white !important;
}

.custom-button.ant-btn-primary.ant-btn-dangerous:not(:disabled):not(
    .ant-btn-background-ghost
  ):hover {
  background-color: var(--feedbacks-negative-600) !important;
}

.custom-button.ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled) {
  border: 1px solid var(--feedbacks-negative-600) !important;
  color: var(--feedbacks-negative-600) !important;
}

.custom-button.ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled):hover {
  background-color: var(--feedbacks-negative-100) !important;
}

.custom-button.ant-btn-default:not(:disabled):hover {
  border: 1px solid var(--primary-600);
  color: var(--primary-600);
}

.custom-modal .ant-modal-title {
  color: #4f4f4f;
  font-weight: 700;
  font-size: 20px;
}

.custom-modal .ant-modal-header {
  border-bottom: none;
}

.custom-modal .ant-modal-footer {
  border-top: none;
  padding: 10px 40px 20px;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-600);
  border-color: transparent;
}

.custom-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  opacity: 0.5;
}

.custom-checkbox .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--primary-10);
}

.ant-alert-info {
  gap: 8px;
  border-color: var(--primary-10);
}

.ant-alert-message {
  color: var(--neutral-700);
}

.custom-marker-cluster {
  background-clip: padding-box;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.custom-marker-cluster:hover {
  transform: scale(1.1);
}

.fade-in {
  animation: fadeIn ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.center-elements {
  display: flex;
  justify-content: center;
}

.wrapper-page {
  height: 91vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
