.custom-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-empty .ant-empty-image {
  margin: 0;
}

.custom-empty img {
  object-fit: cover;
}

.custom-empty .ant-empty-description {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--neutral-900);
}

.custom-empty .ant-empty-footer {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 500px;
  overflow-wrap: break-word;
  gap: 10px;
}

.custom-empty .ant-empty-footer > button {
  margin-top: 20px;
}

.custom-empty h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--neutral-600);
  margin: 0px;
}

.custom-empty.empty-table .ant-empty-description {
  font-size: 14px;
  color: var(--neutral-500);
}
