.grid-value>label {
    color: var(--neutral-600, #666);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.56px;
}

.grid-value>span {
    color: var(--neutral-900, #1A1A1A);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    overflow-wrap: break-word;
}

.grid-value.grid-value-vertical {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
}

.grid-value.grid-value-horizontal {
    display: flex;
    gap: 8px;
    align-items: center;
}

.grid-value-vertical>span {
    white-space: nowrap;
    overflow: hidden;
}