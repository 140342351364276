.view-routing-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-routing-details-header h3 {
  margin: 0px;
}

.view-routing-details-header .ant-image-img {
  width: 90%;
  object-fit: contain;
  margin: 0 20px !important;
}

.view-routing-details-header .view-routing-details-header {
  flex-wrap: wrap;
  gap: 16px;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-top: 3px;
  margin-left: 2px !important;
}

.button-reload-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
