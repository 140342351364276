.legend-config-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 6px;
  margin-right: 10px;
}

.itinerary-roteirizze-container {
  width: fit-content !important;
}

.itinerary-roteirizze-container-service {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.legend-seller-menu {
  max-width: 375px;
  width: 39vh;
  overflow: auto;
  padding: 15px;
  max-height: 70vh;
}

.legend-seller-menu-content {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.header-menu {
  margin-bottom: 16px;
}

.itinerary-list-container {
  padding: 15px;
  width: fit-content;
  font-family: 'Mulish';
  width: 375px;
  max-height: 70vh;
  overflow-y: auto;
}

.itinerary-list-container .itinerary-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.itinerary-list-container .itinerary-list::-webkit-scrollbar {
  width: 4px;
  display: flex;
}

.itinerary-list-container .itinerary-list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

.itinerary-list-container .itinerary-list::-webkit-scrollbar-thumb {
  background: #00356d;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .itinerary-list-container {
    width: 290px;
  }
  .itinerary-list-container .itinerary-card-header-content div h1 {
    font-size: 0.9rem;
  }
  .itinerary-list-container .itinerary-card-header-content div span {
    font-size: 0.6rem;
  }
  .itinerary-list-container .itinerary-filter span {
    font-size: 11px;
    white-space: nowrap;
  }

  .itinerary-list-container .itinerary-filter:nth-of-type(3) span {
    white-space: normal;
    width: 45%;
  }

  .itinerary-list-container .itinerary-filter .ant-picker {
    height: 30px;
    width: 50%;
    font-size: 11px;
  }
}

.legend-config-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 400px) and (max-width: 500px) {
  .itinerary-list-container {
    width: 310px;
  }
}
@media (max-width: 380px) {
  .itinerary-list-container {
    width: 278px;
  }
}
@media (max-width: 360px) {
  .itinerary-list-container {
    width: 265px;
  }
}

.itinerary-client-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-radius: 7px;
  cursor: pointer;
  padding: 10px 8px;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 800;
  color: #ffffff;

  transition: all 1s ease;
}

.itinerary-client-card:hover {
  opacity: 0.8;
}

.itinerary-client-card-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itinerary-client-card strong {
  font-size: 16px;
  font-weight: 800;
  padding: 0 14px;
}

.itinerary-client-card span {
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  max-height: 36px;
}

.itinerary-client-card-flags {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.itinerary-client-card-flags svg {
  margin: 0 2px;
}

.itinerary-client-card-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  min-width: 25px;
  min-height: 25px;

  border-radius: 50px;
  background-color: #ffffff50;
}

@media (max-width: 500px) {
  .itinerary-client-card {
    width: 100%;
    min-width: 240px;
  }
  .itinerary-client-card span {
    max-width: 90px;
    max-height: 30px;
    font-size: 9px;
  }
}

@media (max-height: 850px) {
  .itinerary-list-container {
    max-height: 60vh;
  }
}

.legend-map-checkbox-content {
  display: flex;
  gap: 10px;
  padding-left: 4px;
  align-items: center;
}

.legend-map-checkbox-content > div {
  padding: 0px 6px;
  border-radius: 8px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header-wrapper h1 {
  margin: unset;
}

.header-wrapper span {
  font-size: 16px;
}
