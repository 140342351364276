.custom-collapse {
  display: flex;
  overflow: hidden;
  width: 0;
  transition: width 0.2s ease-in-out, minWidth 0.2s ease-in-out;
}
.custom-collapse > div {
  width: 100%;
  height: 100%;
  margin-bottom: auto;
}

.vertical-collapse-menu__options.vertical-collapse-menu__options--open > :nth-child(4) {
  margin-bottom: 60px !important;
}
