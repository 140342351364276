.vertical-collapse-menu-container {
  top: 0;
  right: 0;
  margin: 1rem;
}

.vertical-collapse-menu {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(26, 26, 26, 0.12);
  border-radius: 8px;
  position: absolute;
  display: flex;
  top: 15px;
  right: 10px;
  z-index: 3;
  height: auto;
}

.vertical-collapse-menu__options {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 4px;
  transition: border-left 0.3s linear;
  border-left: 0.5px solid transparent;
}

.vertical-collapse-menu__options--open {
  border-left: 0.5px solid #c4c4c4;
}

.vertical-collapse-menu__open {
  margin-top: 10px;
  color: #727272;
  animation: appear 0.7s ease-in-out;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.vertical-collapse-menu__content {
  animation: fadeIn 0.8s ease-in-out;
  max-width: fit-content;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.vertical-collapse-menu__options .vertical-collapse-menu__option {
  color: #727272;
  padding-left: 8px;
  padding-right: 8px;
  transition: all 0.1s ease;
  cursor: pointer;
}

.vertical-collapse-menu__options .vertical-collapse-menu__option:not(:last-child) {
  margin-bottom: 10px;
}

.vertical-collapse-menu__options .vertical-collapse-menu__option--selected {
  color: #2f80ed;
}
