.reason-week {
  font-weight: 700;
  font-size: 15px;
}

.reason-day-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 10px;
  margin-top: 5px;
}
