.view-routing-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-routing-details-header h3 {
  margin: 0px;
}

.view-routing-details-header .ant-image-img {
  width: 90%;
  object-fit: contain;
  margin: 0 20px !important;
}

.view-routing-details-header .view-routing-details-header {
  flex-wrap: wrap;
  gap: 16px;
}

.p_full-screen-modal.ant-modal,
.p_full-screen-modal .ant-modal-content {
  height: 100vh;
  width: 100vw;
  background-color: var(--neutral-50);
  margin: 0;
  top: 0;
}

.p_full-screen-modal .ant-modal-header {
  border-bottom: 1px solid var(--neutral-200) !important;
}

.p_full-screen-modal .ant-modal-body {
  padding: 0;
  height: calc(100vh - 84px);
}
