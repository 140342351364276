.roteirizze-map-container.remove-itinerary .leaflet-top.leaflet-right {
  display: none;
}

.roteirizze-map-container.remove-itinerary {
  position: relative;
}

.leaflet-popup-content {
  text-align: left;
}

.leaflet-container {
  font-family: inherit;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: fill;
}
.leaflet-popup-content-wrapper {
  display: flex;
  justify-content: flex-start;
  padding-block: 1px;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  color: #515151;
  font-family: 'Mulish' !important;
  text-align: center;
  border-radius: 20px !important;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: rgba(255, 255, 255, 0.9) !important;
  animation: fadeIn 0.5s ease-in-out;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-control-attribution :not(:last-child) {
  display: none;
}

.roteirizze-map-container .leaflet-control-container .leaflet-top.leaflet-left {
  right: 16px !important;
  left: unset !important;
  top: calc(100% - 100px) !important;
}

.roteirizze-map-container .leaflet-popup.leaflet-zoom-animated {
  bottom: 15px !important;
}

.roteirizze-map-container .leaflet-container {
  height: 100%;
}
